import ApiInstance from "../configs/ApiInstance";

async function UpdateAwsUserDetails(accessToken, attributes) {
    try {
        const response = await ApiInstance.userAwsService.put("/update", { 
            accessToken,
            attributes
         });
        return response.data;
    } catch (error) {
        // console.error("Error occurred when updating user data", error);
        // throw error;
    }
}

export default UpdateAwsUserDetails;