import React, { useEffect, useState } from 'react';
import {
    Container,
    Avatar,
    Typography,
    Grid,
    Box,
    CssBaseline,
    Stack,
    Divider
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ArticleGrid from './ArticleGrid';
import Pagination from './Pagination';
import GetAllArticlesByAuthor from '../services/GetAllArticlesByAuthor'
import { useParams } from 'react-router-dom';
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';

function Profile() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [articles, setArticles] = useState([]);
    const [profileImage, setProfileImage] = useState('');
    const [userDetails, setUserDetails] = useState('');
    const { id: nickname } = useParams();

    useEffect(() => {
        const fetchArticles = async (page) => {
            try {
                const { content, totalPages } = await GetAllArticlesByAuthor(nickname, page);
                const approvedArticles = content.filter(article => article.status === "ACCEPTED");

                const userDetails = await GetUserDetails(nickname);
                setUserDetails(userDetails);
    

                const profileImageId = await FetchProfileImage(userDetails.profileImageKey);
                setProfileImage(profileImageId);
    

                const sortedArticles = approvedArticles.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
                setArticles(sortedArticles);
    
                setTotalPages(totalPages); 
            } catch (error) {
                // console.error("Error fetching articles:", error);
            }
        };
    
        fetchArticles(currentPage); 
    }, [currentPage, nickname]);
    

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <Container disableGutters maxWidth="xl">
            <CssBaseline />
            <Box>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={4} md={3}>
                        <Box
                            height="auto"
                            display="flex"
                            flexDirection="column"
                            p={3}
                            sx={{
                                color: 'white',
                                bgcolor:'gray',
                                minHeight: {
                                    xs: '75vh',
                                    sm: '100vh',
                                },
                                minWidth: {
                                    xl: '50vh'
                                },
                                alignItems: {
                                    xs: 'center',
                                    sm: 'flex-start',
                                },
                                justifyContent: {
                                    xs: 'center',
                                    sm: 'flex-start',
                                },
                                position: {
                                    xs: 'static',
                                    xl: 'fixed'
                                },
                                left: {
                                    xl: 0
                                },
                                top: {
                                    xl: 0
                                },
                                width: {
                                    xl: '250px',
                                },
                                zIndex: 1,
                            }}
                        >
                            <Box p={2}>
                                <Stack spacing={2} alignItems={{ xs: 'center', sm: 'flex-start' }} mt={{ md: 13, xs: 5, }}>
                                    <Avatar
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            border: '2px solid white',
                                            zIndex: 2,
                                            marginTop: 2,
                                        }}
                                        src={profileImage}
                                        alt="Avatar"
                                    />
                                    <Stack spacing={1} alignItems={'flex-start'}>
                                        <Typography fontWeight={800} variant='h5' sx={{ fontSize: { xs: '1.5rem', md: '1.5rem', lg: '1.8rem' } }}>Bio</Typography>
                                        <Typography variant='body1' sx={{ fontSize: { xs: '0.8rem', md: '0.7rem', lg: '1rem' } }}>
                                            {userDetails.bio}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1} alignItems={'flex-start'} direction={'row'} mt={5}>
                                    <EmailIcon sx={{ fontSize: { xs: 20, sm: 20, lg: 20 } }} />
                                    <a href="mailto:gpbandarakusal@gmail.com" style={{ textDecoration: 'none' }}>
                                        <Typography variant="body1" sx={{ color: 'white', fontSize: { xs: '0.8rem', md: '0.7rem', lg: '1rem' } }}>
                                            gpbandarakusal@gmail.com
                                        </Typography>
                                    </a>
                                    <Divider sx={{ bgcolor: 'white' }} />
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} >
                        <Box p={2}>
                            <ArticleGrid articles={articles} />
                            {articles.length > 9 && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default Profile;
