import React, { useState } from 'react';
import { Box, Container, Grid, Tab, Tabs, Typography, Divider } from "@mui/material";
import EditProfile from './EditProfile';
import ChangeRole from './ChangeRole';
import Help from './Help';

function UserProfile() {
    const [selectedView, setSelectedView] = useState('editProfile');
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                setSelectedView('editProfile');
                break;
            // case 1:
            //     setSelectedView('help');
            //     break;
            // case 1:
            //     setSelectedView('role');
            //     break;
            default:
                setSelectedView('editProfile');
        }
    };

    const renderView = () => {
        switch (selectedView) {
            case 'editProfile':
                return <EditProfile />;
            // case 'help':
            //     return <Help />;
            // case 'role':
            //     return <ChangeRole />;
            default:
                return <EditProfile />;
        }
    };

    return (
        <Container maxWidth="lg">
            <Box
                height="auto"
                minHeight={850}
                my={4}
                display="flex"
                flexDirection="column"
                gap={2}
                p={2}
                sx={{
                    border: '1px solid',
                    borderColor: 'black',
                    borderImageSlice: 1,
                    borderRadius: "15px",
                }}
            >


                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-label="profile settings tabs"
 
                    sx={{
                        '& .MuiTab-root': {
                            color: 'black',
                        },
                        '& .Mui-selected': {
                            color: 'black',
                            fontWeight: 'bold',
                        },
                        '& .MuiTab-root:hover': {
                            color: 'black',
                        }
                    }}
                >
                    <Tab
                        disabled
                        label={<Typography variant="body1" fontWeight="bold" sx={{ color: 'black' }}>Edit Profile</Typography>}
                    />
                    {/* <Tab
                        label={<Typography variant="body1" fontWeight="bold" sx={{ color: 'black' }}>Help</Typography>}
                    /> */}
                    {/* <Tab
                        label={<Typography variant="body1" fontWeight="bold" sx={{ color: 'black' }}>Change Role</Typography>}
                    /> */}
                </Tabs>
                <Divider />
                {renderView()}
            </Box>
        </Container>
    );
}

export default UserProfile;
