import ApiInstance from "../configs/ApiInstance";

async function UnLike(articleId, id) {
  try {
    const response = await ApiInstance.articleService.delete(`${articleId}/likes/${id}`, {
        });
     return response;
  } catch (error) {
    // console.log(error);
    // throw error;
  }
}

export default UnLike;
