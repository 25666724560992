import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent, CardActions, Chip } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';


function ArticleGrid({ articles }) {
  const [articleImages, setArticleImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        articles.map(async (article) => {
          const imageUrl = await GetArticleImage(article.articleImageKey);
          return { [article.id]: imageUrl };
        })
      );
      setArticleImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [articles]);

  if (!articles || articles.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
          sx={{
            mt: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            minHeight: '85vh',
            minWidth: {
              xl: '120vh'
            },
            border: "2px solid black",
            borderRadius: "15px"
          }}
        >
          <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' }, textAlign: 'center' }}>
            No Articles Available
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} mt={1}>
        {articles.map((article) => (
          <Grid item key={article.id} xs={12} sm={6} md={4} lg={4} xl={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: { md: "150px", xs: '250px', lg: '185px' },
                width: { md: '200px', lg: "275px", xl: '300px' },
                position: "relative",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={articleImages[article.id] || ""}
                alt={article.title}
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  zIndex: 0,
                }}
                onClick={() => navigateToUrl(`${article.author}/articles/${article.id}`)}
              />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  background: 'linear-gradient(to top, black, rgba(255, 255, 255, 0))',
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              />

              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: 2,
                  color: "white",
                  p: 2,
                }}
              >
                <Typography fontSize={"1.05rem"} sx={{ color: "white" }}>
                  {article.title}
                </Typography>
              </CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ArticleGrid;
