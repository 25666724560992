import React, { useEffect, useState } from 'react';
import { Alert, Avatar, Box, Button, Container, Grid, Stack, TextField, Typography, Snackbar, CircularProgress, FormHelperText, Select, MenuItem, Chip, Collapse, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateAwsUserDetails from "../services/UpdateAwsUserDetails";
import GetUserDetails from '../services/GetUserData';
import FetchProfileImage from '../services/FetchProfileImage';
import PutUserDetails from '../services/PutUserDetails';
import UpdateProfilePicture from '../services/UpdateProfilePicture';
import CloseIcon from '@mui/icons-material/Close';
import { navigateToUrl } from 'single-spa';

import "../styles/styles.css"

const categories = [
    'ENTERTAINMENT',
    'SPORT',
    'FASHION',
    'TRAVEL',
    'FOOD',
    'TECHNOLOGY',
    'HEALTH',
    'LIFESTYLE',
    'BUSINESS',
    'EDUCATION',
    'ENVIRONMENT',
    'CULTURE',
    'HISTORY',
    'PETS',
    'BOOKS',
    'GAMING'
];

function EditProfile() {
    const [bio, setBio] = useState('');
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [nickname, setNickname] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [openError, setOpenError] = useState(false);


    const showAlert = (message) => {
        setErrorMessage(message);
        setOpenError(true);
    };

    const handleErrorClose = () => {
        setErrorMessage(''); 
        setOpenError(false);
    };
    
    const handleCategoryChange = (event) => {
        const { value } = event.target;
        if (value.length <= 4) {
            setSelectedCategories(value);
        } else {
            showAlert({ message: "You can select up to 4 categories only." });
        }
    };

    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = getUserAttribute(parsedUser, 'nickname');
            fetchUserDetails(nickname);
        }
    }, []);

    const fetchUserDetails = async (nickname) => {
        try {
            const userDetails = await GetUserDetails(nickname);
            setUserData(userDetails);
            setNickname(userDetails.nickname);
            setGivenName(userDetails.given_name);
            setFamilyName(userDetails.family_name);
            setSelectedCategories(userDetails.favourites)
            setBio(userDetails.bio || '');
            if (userDetails.profileImageKey) {
                const imageUrl = await FetchProfileImage(userDetails.profileImageKey);
                setProfileImage(imageUrl);
            }
        } catch (error) {
            showAlert("Failed to fetch user details. Please try again.");
        }
    };

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const charLimit = 300;

    const handleBioChange = (event) => {
        const newBio = event.target.value;
        if (newBio.length <= charLimit) {
            setBio(newBio);
        } else {
            showAlert("Character limit reached!");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        const accessToken = userData?.AccessToken || sessionStorage.getItem('accessToken');
        const attributes = [
            { Name: 'given_name', Value: givenName },
            { Name: 'family_name', Value: familyName },
        ];
        if (!familyName) {
            showAlert("Please provide your last name.");
            return;
        }
        if (!nickname) {
            showAlert("Please provide a nickname.");
            return;
        }
        if (!bio) {
            showAlert("Please provide a bio.");
            return;
        }
        if (!selectedCategories.length) {
            showAlert("Please select at least one category.");
            return;
        }
        if (!accessToken) {
            showAlert("Your session has expired. Please log out and try again.");
            return;
        }

        try {
            await UpdateAwsUserDetails(accessToken, attributes);
            await PutUserDetails(nickname, givenName, familyName, bio, selectedCategories);
            navigateToUrl('/');
        } catch (error) {
            showAlert("An error occurred while saving your profile. Please try again."); 
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                await UpdateProfilePicture(userData.profileImageKey, file);
                setProfileImage(URL.createObjectURL(file));
                alert("Profile picture updated successfully");
                window.location.reload();
            } catch (error) {
                showAlert("Error updating profile picture. Please try again.");
            }
        }
    };

    if (!userData) {
        return (
            <Container maxWidth="xs">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    <CircularProgress size={100} sx={{ color: 'black' }} />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" >
            <Stack spacing={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Stack spacing={3}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <Avatar
                                    src={profileImage}
                                    alt={nickname}
                                    sx={{
                                        width: { xs: 80, sm: 100, md: 120 },
                                        height: { xs: 80, sm: 100, md: 120 },
                                    }}
                                />
                                <Button
                                    disableRipple
                                    disableFocusRipple
                                    sx={{
                                        position: 'left',
                                        bottom: 0,
                                        top: 50,
                                        right: 0,
                                        backgroundColor: 'transparent',
                                        padding: 1,
                                        minWidth: 'auto',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    component="label"
                                >
                                    <EditIcon sx={{ color: 'black' }} />
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>
                            <Box>
                                <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Username</Typography>
                                <TextField
                                    variant='outlined'
                                    inputProps={{ readOnly: true }}
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                    fullWidth
                                    sx={{ '& .MuiInputBase-input': { cursor: 'default' } }}
                                    className="gradient-textfield"
                                />
                            </Box>

                            <Box>
                                <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Bio</Typography>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    multiline
                                    rows={5}
                                    value={bio}
                                    onChange={handleBioChange}
                                    inputProps={{ maxLength: charLimit }}
                                    className="gradient-textfield"
                                />
                                <Typography variant='caption' display='block' gutterBottom>
                                    {bio.length}/{charLimit} characters
                                </Typography>
                            </Box>

                            <Box>
                                <Typography sx={{ marginBottom: 1 }} fontWeight={600}>First Name</Typography>
                                <TextField
                                    variant='outlined'
                                    value={givenName}
                                    onChange={(e) => setGivenName(e.target.value)}
                                    fullWidth
                                    className="gradient-textfield"
                                />
                            </Box>

                            <Box>
                                <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Last Name</Typography>
                                <TextField
                                    variant='outlined'
                                    value={familyName}
                                    onChange={(e) => setFamilyName(e.target.value)}
                                    fullWidth
                                    className="gradient-textfield"
                                />
                            </Box>

                            <Box>
                            <Typography sx={{ marginBottom: 1 }} fontWeight={600}>Favorite Categories</Typography>
                                    <Select
                                        fullWidth
                                        className="gradient-select"
                                        name="categories"
                                        multiple
                                        value={selectedCategories}
                                        onChange={handleCategoryChange}
                                    >
                                        {categories.map((category) => (
                                            <MenuItem key={category} value={category}>
                                                {category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>Select up to 4 categories</FormHelperText>

                                    <Box
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                    }}
                                >
                                    {selectedCategories.map((value) => (
                                        <Chip key={value} label={value} sx={{ backgroundColor: 'black', color: 'white' }} />
                                    ))}
                                </Box>

                                    </Box>

                            <Button
                                type="submit"
                                onClick={handleSave}
                                variant="contained"
                                sx={{
                                    fontWeight: 550,
                                    letterSpacing: 2,
                                    background: 'black',
                                    "&:hover": {
                                        background: 'black',
                                    },
                                }}>
                                Save Changes
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>

            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={handleErrorClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

        </Container>
    );
}

export default EditProfile;
