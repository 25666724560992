import ApiInstance from "../configs/ApiInstance";

async function UpdateProfilePicture(profileImageKey, file) {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await ApiInstance.userAwsService.put(`/update-image/${profileImageKey}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        // console.error('Error updating profile picture:', error);
        // throw error;
    }
}

export default UpdateProfilePicture;
