import ApiInstance from "../configs/ApiInstance";

async function PutUserDetails(nickname, givenName, familyName, bio, selectedCategories) {
    try {
        const given_name = givenName;
        const family_name = familyName;
        const response = await ApiInstance.userService.put(`${nickname}`,{
            given_name,
            family_name,
            bio,
            favourites:selectedCategories
        });
        const data = response.data;

        return data;
    } catch (error) {
        // console.log("Error occurred when user update", error);
        // throw error;
    }
}

export default PutUserDetails;