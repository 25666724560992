import ApiInstance from "../configs/ApiInstance"

async function GetUserDetails(nickName) {
    try {
        const response = await ApiInstance.userService.get(`/${nickName}`);
        const data = response.data;
        return data;
    } catch (error) {
        // console.error("Error occurred when getting user data", error)
        // throw error;
    }
}

export default GetUserDetails;