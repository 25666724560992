import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetArticleById from '../services/GetArticleById';
import GetArticleImage from '../services/GetArticleImage';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Banner from './Banner';
import Content from './Content';
import { Container, Typography, Link,CssBaseline } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FetchProfileImage from '../services/FetchProfileImage';
import GetUserDetails from '../services/GetUserData';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Article() {
  const { articleId: articleId } = useParams();
  const { id: userId } = useParams();
  const [article, setArticle] = useState(null);
  const [profileImage, setProfileImage] = useState('');
  const [articleImageKey, setArticleImageKey] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const articleData = await GetArticleById(articleId);
        const imageUrl = await GetArticleImage(articleData.articleImageKey);
        const authorDetails = await GetUserDetails(articleData.author)
        const authorProfileImage = await FetchProfileImage(authorDetails.profileImageKey)
        setProfileImage(authorProfileImage)
        setArticleImageKey(imageUrl);
        setArticle(articleData);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    fetchArticle();
  }, [articleId]);

  return (
    <div>
      <CssBaseline/>
      <Banner articleImageKey={articleImageKey}/>
      <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" >
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href={`/profile/${userId}`}
        >
          <AccountCircleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {userId}
        </Link>
        <Typography
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
        >
           {article?.title}
        </Typography>    
      </Breadcrumbs>
      </Container>
      {article && <Content article={article} profileImage={profileImage}/>}
    </div>
  );
}

export default Article;
